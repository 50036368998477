<template>
  <tab-dialog
    :title="$t('company.titles.configurations')"
    :dialog="showTabDialog"
    :tabs="tabsConfig"
    :valid="valid"
    :is-editable="true"
    :loading="loading"
    :card-props="{
      height: 'auto',
      title: {
        class: 'headline secondary t-bw-secondary--text'
      }
    }"
    :show-actions="currentTab.showActions"
    :show-save-action="currentTab.save"
    @update:show-actions="currentTab = $event"
    @on:save="confirm"
    @on:close="close"
  >
    <!-- Tab: General -->
    <template v-slot:general>
      <GeneralConfigTab :company="company"
                        @on:show-actions="updateTabShowAction(0, $event)"
                        @update:valid="valid = $event" />
    </template>

    <!-- Tab: Blockchain -->
    <template v-slot:blockchain>
      <BlockchainConfigTab
        @on:show-actions="updateTabShowAction(1, $event)"
        @update:valid="valid = $event" />
    </template>

    <!-- Tab: Export -->
    <template v-slot:export>
      <ImportExportConfigTab :company="company"
                       @on:show-actions="updateTabShowAction(2, $event)"
                       @update:valid="valid = $event" />
    </template>

    <!-- Tab: Identity -->
    <template v-slot:identity>
      <IdentityConfigTab ref="identityConfigForm" @on:show-actions="updateTabShowAction(3, $event)"
                         @update:valid="valid = $event" />
    </template>

    <template v-slot:bottom-actions>
      <v-btn
        small
        color="gray"
        elevation="0"
        class="t-bw-gray--text"
        v-if="currentTab.slotName === 'identity'"
        @click="isPreviewMode ? restoreOriginalColors() : applyPreviewColors()"
      >
        <v-icon left>{{ isPreviewMode ? 'mdi-backup-restore' : 'mdi-eye' }}</v-icon>
        {{ isPreviewMode ? $t('customization.buttons.restore') : $t('customization.buttons.preview') }}
      </v-btn>
    </template>
  </tab-dialog>
</template>

<script>
  import { mapActions, mapGetters } from "vuex"
  import CompanyService from "@/services/CompanyService"
  import TabDialog from "@/components/common/TabDialog"
  import WalletsTable from "@/components/UserOptions/Blockcahin/WalletsTable";
  import FormWalletComponent from "@/components/UserOptions/Blockcahin/WalletFormComponent";
  import GeneralConfigTab from "@/module/configuration/company/components/GeneralConfigTab"
  import BlockchainConfigTab from "@/module/configuration/company/components/BlockchainConfigTab"
  import ImportExportConfigTab from "@/module/configuration/company/components/ImportExportConfigTab"
  import MainSection from "@/module/configuration/producer/components/MainSection"
  import IdentityConfigTab from "@/module/configuration/company/components/IdentityConfigTab"
  import i18n from "@/plugins/i18n"

  const PROFILE = JSON.parse(localStorage.getItem('profile'));

  export default {
    components: {
      IdentityConfigTab,
      MainSection,
      ImportExportConfigTab,
      BlockchainConfigTab,
      GeneralConfigTab,
      TabDialog,
      FormWalletComponent,
      WalletsTable,
    },
    props: {
      showTabDialog: {
        type: Boolean,
        required: true,
      },
    },
    data() {
      return {
        valid: false,
        tab: 0,
        showActions: true,
        isPreviewMode: localStorage.getItem('preview:colors') === 'true',
        company: {
          traceability: {
            structure: {
              number: {
                afterPoint: null,
              },
            },
          },
          export: {
            recordExportLimit: null,
            inspectionExportLimit: null,
          },
          import: {
            recordImportLimit: null,
          },
        },
        loading: false,
        loadingReset: false,
        loadingCancel: false,
        configUser: {},
        currentTab: null,
        form: {
          company: false,
        },
        tabsConfig: [
          { name: i18n.t('general.titles.general'), icon: 'mdi-information', slotName: 'general', visible: true, showActions: true, save: true },
          { name: 'Blockchain', icon: 'mdi-network', slotName: 'blockchain', visible: PROFILE.company_id === 1, showActions: true, save: false },
          { name: `${i18n.t('general.buttons.import').slice(0,3).toUpperCase() + '/' + i18n.t('general.buttons.export').slice(0,3).toUpperCase()}`, icon: 'mdi-arrow-collapse', slotName: 'export', visible: true, showActions: true, save: true },
          { name: i18n.t('customization.titles.identity'), icon: 'mdi-palette', slotName: 'identity', visible: true, showActions: true, save: true },
        ],
      };
    },
    async created() {
      this.configUser = JSON.parse(localStorage.getItem('config'))
      this.company.traceability.structure.number = this.configUser.company.traceability.structure.number
      this.company.import.recordImportLimit = this.configUser.company.import.recordImportLimit
      this.company.export.recordExportLimit = this.configUser.company.export.recordExportLimit
      this.company.export.inspectionExportLimit = this.configUser.company.export.inspectionExportLimit

      this.currentTab = this.tabsConfig[0];
    },
    computed: {
      ...mapGetters({
        compareMax: 'general/compareMax'
      }),
    },
    methods: {
      ...mapActions({
        createConfigUser: 'authority/createConfigUser',
        loadConfigUser: 'authority/loadConfigUser',
      }),
      updateTabShowAction(tabIndex, value) {
        this.tabsConfig[tabIndex].showActions = value;
      },
      async confirm() {
        if (this.currentTab.slotName === 'identity') {
          await this.$refs.identityConfigForm.saveColors();
          localStorage.removeItem('preview:colors');
        } else {
          this.loading = true;
          const config = this.manageModule('create');

          // Import
          this.configUser.company.import.recordImportLimit = config.recordImportLimit;

          // Export
          this.configUser.company.export.recordExportLimit = config.recordExportLimit;
          this.configUser.company.export.inspectionExportLimit = config.inspectionExportLimit;

          await CompanyService.setConfig(config).finally(() => {
            this.loading = false;
            this.dialog = false;
          });

          console.log(this.configUser)
          localStorage.setItem(`config`, JSON.stringify(this.configUser));
        }

        this.$emit('on:close');
      },
      manageModule(method) {
        let config = {}

        if (method === 'create') {
          config = {
            companyStructuresPreferences: {
              afterPoint: this.company.traceability.structure.number.afterPoint
            },
            recordImportLimit: this.company.import.recordImportLimit,
            recordExportLimit: this.company.export.recordExportLimit,
            inspectionExportLimit: this.company.export.inspectionExportLimit
          }
          this.configUser.company.traceability.structure.number = this.company.traceability.structure.number
          localStorage.setItem(`config`, JSON.stringify(this.configUser))
          return config
        } else if ('reset') {
          config = {
            companyStructuresPreferences: {
              afterPoint: null
            },
            recordImportLimit: 0,
            recordExportLimit: 0,
            inspectionExportLimit: 0
          }
          this.company.traceability.structure.number.afterPoint = null
          this.configUser.company.traceability.structure.number = this.company.traceability.structure.number
          localStorage.setItem(`config`, JSON.stringify(this.configUser))
          return config
        }
      },

      async cancelDialogConfig() {
        this.loadingCancel = true;

        if (this.currentTab.slotName === 'identity') {
          await this.restoreOriginalColors()
        } else {
          await this.loadConfigUser([this.$toast]).finally(() => {
            this.loadingCancel = false;
            this.dialog = false;
          })
        }
      },

      async applyPreviewColors() {
        await this.$refs.identityConfigForm.applyPreview();
        this.isPreviewMode = true;
        localStorage.setItem('preview:colors', 'true');
        this.$emit('on:close');
      },

      async restoreOriginalColors() {
        await this.$refs.identityConfigForm.restoreOriginalColors();
        this.isPreviewMode = false;
        localStorage.removeItem('preview:colors');
        await this.$refs.identityConfigForm.loadInitConfiguration();
      },

      async close() {
        await this.cancelDialogConfig();
        this.$emit('on:close');
      }
    },

    destroyed() {
      this.configUser = {}
      this.$root.$off('updateOptions')
    },
  };
</script>
