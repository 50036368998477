<template>
  <div>
    <form-wrapper ref="form" reference="importConfigForm" boxed :form-title="$t('general.buttons.import').slice(0,1) + $t('general.buttons.import').slice(1,$t('general.buttons.import').length)" @update:valid="$emit('update:valid', $event)">
      <template #fields>
        <v-col cols="12" md="6">
          <v-label>{{ $t('modules.name.Record') }}</v-label>
          <v-text-field
            :placeholder="(company.import.recordImportLimit === null || company.import.recordImportLimit === '') ? $t('company.titles.defaultExportRecordsValue') : ''"
            id="importRecordLimit"
            dense
            outlined
            color="primary"
            clearable
            v-model="company.import.recordImportLimit"
            @keypress="onlyAllowNumbers($event)"
            @input="filterInput('import', 'recordImportLimit')"
          />
        </v-col>
      </template>
    </form-wrapper>

    <form-wrapper class="my-12" ref="form" reference="exportConfigForm" boxed :form-title="$t('general.buttons.export').slice(0,1) + $t('general.buttons.export').slice(1,$t('general.buttons.export').length)" @update:valid="$emit('update:valid', $event)">
      <template #fields>
        <v-col cols="12" md="6">
          <v-label>{{ $t('modules.name.Record') }}</v-label>
          <v-text-field
            :placeholder="(company.export.recordExportLimit === null || company.export.recordExportLimit === '') ? $t('company.titles.defaultExportRecordsValue') : ''"
            id="exportRecordLimit"
            dense
            outlined
            color="primary"
            clearable
            v-model="company.export.recordExportLimit"
            @keypress="onlyAllowNumbers($event)"
            @input="filterInput('export', 'recordExportLimit')"
          />
        </v-col>
        <v-col cols="12" md="6">
          <v-label>{{ $t('modules.name.Survey') }}</v-label>
          <v-text-field
            :placeholder="(company.export.inspectionExportLimit === null || company.export.inspectionExportLimit === '') ? $t('company.titles.defaultExportInspectionsValue') : ''"
            id="inspectionExportLimit"
            dense
            outlined
            color="primary"
            clearable
            v-model="company.export.inspectionExportLimit"
            @keypress="onlyAllowNumbers($event)"
            @input="filterInput('export', 'inspectionExportLimit')"
          />
        </v-col>
      </template>
    </form-wrapper>
  </div>
</template>

<script>
  import FormWrapper from "@/components/common/FormWrapper";

  export default {
    components: { FormWrapper },
    props: {
      company: {
        type: Object,
        required: true,
      },
      isEditable: {
        type: Boolean,
        default: true
      }
    },
    methods: {
      onlyAllowNumbers(event) {
        const keyCode = event.keyCode ? event.keyCode : event.which;
        if (keyCode < 48 || keyCode > 57) {
          event.preventDefault();
        }
      },
      filterInput(section, key) {
        const value = this.company[section][key];
        if (value !== null && value !== undefined && value !== '') {
          const numericValue = value.replace(/\D/g, '');
          this.$set(this.company[section], key, numericValue);
        }
      }
    },
    beforeDestroy() {
      this.$refs.form.$refs.exportConfigForm.reset();
      console.log("ExportConfigTab Destroyed");
    }
  };
</script>

<style scoped>
  .display-field {
    padding: 8px;
    background-color: #f5f5f5;
    border-radius: .2rem;
    display: flex;
    align-items: center;
  }
</style>
